import React, { Component } from "react";
import { connect } from "react-redux";
import { BaseComponent } from "../../../../common/base-component";
import { BASE_IMAGES_URL, APP_API_KEY } from "../../../../common/constants";
import _ from "lodash";
import EditAdvertisement from "./editadds";
import { onEditadvertisement, deleteAds, fetchAllAdds } from "../actions/index";
import BanAdvertisement from "./banAdd";
import UnBanAdvertisement from "./unbanAdd";
import LocaleStrings from "../../../languages/index.js";
import { confirmAlert } from "react-confirm-alert";
import { NotificationManager } from "react-notifications";
class AllAdvertismentListing extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }
  openModal = (e, obj) => {
    this.props.onEditadvertisement(obj, this.props.language);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  openBanModal = (e, obj) => {
    this.props.onEditadvertisement(obj);
    this.setState({ open: true });
  };
  closebanModal = () => this.setState({ open: false });

  openUnBanModal = (e, obj) => {
    this.props.onEditadvertisement(obj);
    this.setState({ unbanopen: true });
  };
  closeUnbanModal = () => this.setState({ unbanopen: false });

  _handleDelete(e, values) {
    // console.log("values", values);
    confirmAlert({
      title: "Delete Ad",
      message: "Are you sure you want to delete this add??",
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.deleteAds(this.props.session, values, (resource) => {
              NotificationManager.success(LocaleStrings.common_deleteSucess);
              this.props.fetchAllAdds(
                this.props.session,
                this.props.currentPage,
                "",
                (CallBackResponse) => {}
              );
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var { values, language } = this.props;
    // console.log('values133closeModal', values);
    var img = `${BASE_IMAGES_URL}/adbanners/${values.banner}?api_key=${APP_API_KEY}`;
    // console.log('img',img);
    let text = values.banner;
    const myArray = text.split(".");
    // console.log('myArray',myArray);

    let type = "";
    if (myArray.length > 0) {
      type = myArray["1"];
    }

    // console.log('type',type);
    return (
      <>
        {values.isactive == 0 ? (
          <>
            {" "}
            <tr
              className="bg-white border-b-2 border-gray-50 bg-gray-50 hover-all "
              id={this.props.key}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {/* <img src={img} className="ads-image" /> */}
                {type == "jpg" ? (
                  <img
                    src={img}
                    style={{ height: "200px", width: "100%" }}
                    className="ads-image"
                  />
                ) : type == "mp4" ? (
                  <video style={{ height: "200px", width: "100%" }} controls>
                    <source src={img} type="video/ogg" />
                  </video>
                ) : (
                  "-"
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {values.productcategory_by_categoryids == null ? (
                  <>
                    {/* {LocaleStrings.ads_management_adsonHome} */}
                    External Link
                  </>
                ) : (
                  <>
                    {values.productcategory_by_categoryids != null ? (
                      <div>
                        {" "}
                        {LocaleStrings.ads_management_adsonCategory}
                        {
                          values?.productcategory_by_categoryids
                            ?.categoryname_en
                        }
                      </div>
                    ) : (
                      <></>
                    )}
                    {values.productsubcategory_by_subcategoryids != null ? (
                      <div>
                        {" "}
                        {LocaleStrings.ads_management_adsonsubCategory}
                        {
                          values?.productsubcategory_by_subcategoryids
                            ?.subcategoryname_en
                        }
                      </div>
                    ) : (
                      <></>
                    )}
                    {values.products_by_productid != null ? (
                      <div>
                        {" "}
                        {/* Product :{values.products_by_productid.productname_en} */}
                        {LocaleStrings.ads_management_adsonsProduct}
                        {/* {ProductTextLocalized(
                          values.products_by_productid.productname_en,
                          values.products_by_productid.productname_ar,
                          language
                        )} */}
                        {values?.products_by_productid?.productname_en.length >
                        35
                          ? values?.products_by_productid?.productname_en.substring(
                              0,
                              25
                            ) + "..."
                          : values?.products_by_productid?.productname_en}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {values.whereto == "category" ? (
                  <>
                    Category :-{" "}
                    {
                      values?.productcategory_by_whereto_categoryid
                        ?.categoryname_en
                    }
                  </>
                ) : (
                  <>
                    Product:-{" "}
                    {values?.products_by_whereto_productid?.productname_en}
                  </>
                )}

                {/* {_.startCase(values.repeattimes ) - "times" - "afterevery" (values.rowsno) - "rows" - (values.position)} */}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openUnBanModal(e, values)}>
                      {LocaleStrings.unban}
                    </button>

                    {this.state.unbanopen ? (
                      <UnBanAdvertisement
                        isOpen={this.state.unbanopen}
                        onClose={this.closeUnbanModal}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this._handleDelete(e, values)}>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr
              className="bg-white border-b-2 border-gray-50 hover-all"
              id={this.props.key}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {type == "jpg" ? (
                  <img
                    src={img}
                    style={{ height: "200px", width: "100%" }}
                    className="ads-image"
                  />
                ) : type == "mp4" ? (
                  <video style={{ height: "200px", width: "100%" }} controls>
                    <source src={img} type="video/ogg" />
                  </video>
                ) : (
                  "-"
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {values.categoryids == null &&
                values.subcategoryids == null &&
                values.productid == null ? (
                  <>External Link</>
                ) : (
                  <>
                    {values.productcategory_by_categoryids != null ? (
                      <div>
                        {" "}
                        {LocaleStrings.ads_management_adsonCategory}
                        {
                          values?.productcategory_by_categoryids
                            ?.categoryname_en
                        }
                      </div>
                    ) : (
                      <></>
                    )}
                    {values.productsubcategory_by_subcategoryids != null ? (
                      <div>
                        {" "}
                        {LocaleStrings.ads_management_adsonsubCategory}
                        {
                          values?.productsubcategory_by_subcategoryids
                            ?.subcategoryname_en
                        }
                      </div>
                    ) : (
                      <></>
                    )}
                    {values.products_by_productid != null ? (
                      <div>
                        {" "}
                        {LocaleStrings.ads_management_adsonsProduct}
                        {values?.products_by_productid?.productname_en.length >
                        35
                          ? values?.products_by_productid?.productname_en.substring(
                              0,
                              25
                            ) + "..."
                          : values?.products_by_productid?.productname_en}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {values.whereto == "category" ? (
                  <>
                    Category :-{" "}
                    {
                      values?.productcategory_by_whereto_categoryid
                        ?.categoryname_en
                    }
                  </>
                ) : (
                  <>
                    Product:-{" "}
                    {values?.products_by_whereto_productid?.productname_en}
                  </>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <div className="action-button-area" style={{ display: "flex" }}>
                  <div className="">
                    <button
                      className="button"
                      onClick={(e) => this.openModal(e, values)}>
                      {LocaleStrings.edit}
                    </button>
                    {this.state.isOpen ? (
                      <EditAdvertisement
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        search={this.props.search}
                        // values={this.props.values}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="ml-3">
                    <button
                      className="button"
                      onClick={(e) => this._handleDelete(e, values)}>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>{" "}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu: state.sidebarSelectedMenu,
    language: state.language,
  };
}

export default connect(mapStateToProps, {
  onEditadvertisement,
  deleteAds,
  fetchAllAdds,
})(AllAdvertismentListing);
