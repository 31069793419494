import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../components/languages/index";
class NoContentSlideOver extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  render() {
    return (
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-32">
            <div className="flex-shrink-0 flex justify-center">
              <span href="/" className="inline-flex">
                <span className="sr-only">{LocaleStrings.no_content}</span>
                <img
                  loading="lazy"
                  className="h-12 w-auto"
                  src={this.props.imageprop}
                  alt="icon"
                />
              </span>
            </div>
            <div className="text-center">
              <h1 className="text-base text-secondary truncate my-4">
                {this.props.headerprop}
              </h1>
              <p className="text-sm text-secondary truncate">
                {this.props.subheaderprop}
              </p>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    language: state.language,
  };
}

export default connect(mapStateToProps, {})(NoContentSlideOver);
