import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { SearchIcon } from "@heroicons/react/solid";
import SearchNewCustomer from "./searchnewcustomer";
import Customertabs from "./customertabs";
import CustomerListitem from "./customerlist-items.js";
import {
  fetchCustomer,
  newtabs,
  searchCustomer,
  fetchOrderMaster,
} from "../actions/index.js";
import _ from "lodash";
import Pagination from "react-js-pagination";
import { NotificationContainer } from "react-notifications";
import BaseLoader from "../../../../common/base-loader.js";
import { classNames } from "../../../../common/common-functions";

class CustomerManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showUploadSection : false,
      loading: true,
      loaderDesc: LocaleStrings.customer_management,
      pageNumber: 1,
      customerSearchText: "",
      search: "",
    };

    // All Binded Functions
  }

  componentDidMount() {
    var parts = this.props.history.location.pathname.substring(11).split("/");
    var answer = parts[0];
    var singleNavigationRow = _.find(this.props.navigation.data, {
      href: answer,
    });

    if (singleNavigationRow == undefined) {
      this.props.history.push("/login");
    }
    this.props.fetchCustomer(this.props.session, 0, (CallBackResponse) => {
      this.setState({ loading: false });
    });
    this.props.fetchOrderMaster(this.props.session, (CallBackResponse) => {});
    this.props.newtabs();
  }

  handleSearch = (event) => {
    var search = event.target.value;
    var pageNumber = 1;

    if (search == "") {
      this.props.fetchCustomer(this.props.session, 0, (CallBackResponse) => {});
    } else {
      this.props.searchCustomer(this.props.session, search);
    }
    this.setState({ search, pageNumber });
  };

  // paginationCallback = (pageNumber) => {
  //   //  console.log("pgno", pageNumber)
  //   this.props.fetchCustomer(this.props.session, pageNumber, (response) => {});

  //   this.setState({ ...this.state, pageNumber });
  // };
  paginationCallback = (pageNumber) => {
    //  console.log("pgno", pageNumber)
    if (this.state.search == "") {
      this.props.fetchCustomer(
        this.props.session,
        pageNumber,
        (response) => {}
      );
      this.props.fetchOrderMaster(this.props.session, (CallBackResponse) => {});
    } else {
      this.props.searchCustomer(
        this.props.session,
        this.state.search,
        pageNumber
      );
    }

    this.setState({ ...this.state, pageNumber });
  };

  renderList() {
    let searchText = this.state.customerSearchText;
    let filtered = [];

    if (this.state.customerSearchText !== "") {
      // If Search Key Found
      let listing = this.props.customerlist.data;

      filtered = _.filter(listing, function (o) {
        return o.username.toLowerCase().indexOf(_.toLower(searchText)) > -1;
      });
    } else {
      filtered = this.props.customerlist.data;
    }

    let pageDataLength = this.props.customerlist.data.length;

    let { pageNumber } = this.state;
    // console.log('check');
    var items = _.map(filtered, (values) => (
      <CustomerListitem
        key={values.userid}
        values={values}
        pagination={this.paginationCallback}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    // console.log('check', this.state.pageNumber);
    return items;
  }

  render() {
    let { language } = this.props;
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {!this.props.newtablist ? (
              <>
                <div
                  className="grid gap-4 grid-rows-1 px-6 md:grid-rows-3 sm:grid-rows-3 lg:grid-rows-1"
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                    display: "block",
                  }}>
                  <div className="text-2xl font-semibold text-secondary">
                    CUSTOMER MANAGEMENT
                  </div>
                  <SearchNewCustomer pagination={this.paginationCallback} />
                  <div
                    className="relative md:w-6/12 xs:w-full text-ternary focus-within:text-secondary smallscreen-search"
                    style={{ marginTop: -35 }}>
                    <div
                      className={classNames(
                        language == "en" ? "left-0 " : "right-0",
                        "absolute inset-y-0 flex items-center pointer-events-none px-1"
                      )}>
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className={classNames(
                        language == "en" ? "pl-8 pr-3" : "pr-8 pl-3",
                        "block lg:w-6/12 xs:w-full sm:w-full h-full  py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                      )}
                      placeholder={LocaleStrings.search}
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearch}
                    />
                  </div>
                  {this.props.customerlist.count > 0 ? (
                    <div className="panel-body mt-6">
                      {/* <TableContent /> */}
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-custWhite">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.name}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.email}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.phonenumber}
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      {LocaleStrings.action}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{this.renderList()}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.props.customerlist.count > 7 ? (
                        <Pagination
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={7}
                          totalItemsCount={this.props.customerlist.count}
                          pageRangeDisplayed={10}
                          onChange={this.paginationCallback}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="mt-48 center-item text-ternary">
                      Customer(s) Not Found
                    </div>
                  )}

                  <NotificationContainer />
                </div>
              </>
            ) : (
              <Customertabs newtab={this.props.newtablist} />
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user, customerlist, newtablist, language } = state;
  return {
    session,
    user,
    customerlist,
    newtablist,
    language,
    navigation: state.sideBarMenus,
  };
}

export default connect(mapStateToProps, {
  fetchCustomer,
  newtabs,
  searchCustomer,
  fetchOrderMaster,
})(CustomerManagement);
