import React from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import {
  addnewCustomerdf,
  fetchCustomer,
  emaildbCheck,
} from "../actions/index";
// import { showError, showSuccess } from "ReduxErrorBar";
import {
  DATE_FORMAT,
  validateEmail,
  DATE_FORMAT_WITH_TIME_WITH_SECOND,
} from "../../../../common/constants";
import {
  classNames,
  isValidQatarPhoneNumber,
} from "../../../../common/common-functions";
import { countryPhoneLength } from "../../../../actions/index.js";
import { BaseComponent } from "../../../../common/base-component";
import moment from "moment-timezone";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

const gender = [
  { name: "Rather not  say", value: "", index: 0 },
  { name: "MALE", value: "male", index: 1 },
  { name: "FEMALE", value: "female", index: 2 },
];

class SearchNewCustomer extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      genderOptions: gender,
      btnLoader: false,
      show: false,
      show1: false,
      phone: "",
      country: "",
    };

    // All Binded Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }
  openModal() {
    this.setState({ open: true });
    this.props.initialize();
  }
  closeModal() {
    this.setState({ open: false });
    this.props.destroy();
  }

  changeShow() {
    let { show } = this.state;
    this.setState({ show: !show });
  }
  changeShow1() {
    let { show1 } = this.state;
    this.setState({ show1: !show1 });
  }

  onSubmitForm(values) {
    // console.log("submit values :- ", values);
    if (values.password.length < 6 || values.new_password < 6) {
      NotificationManager.error("Minimum 6 character required for password");
      return false;
    }
    // console.log("submit values.length :- ", values.length);
    let dob = null;
    if (
      values.hasOwnProperty("dob") &&
      (values.dob !== null || values.dob !== "")
    ) {
      dob = moment(values.dob).format(DATE_FORMAT_WITH_TIME_WITH_SECOND);
    }
    if (Object.keys(values).length === 0) {
      NotificationManager.error("Mandatory Fields are required");
      return false;
    }
    if (_.trim(values.new_password) !== _.trim(values.password)) {
      NotificationManager.error("Sorry! Password Didn't Match");
      // this.setState({ open: false });
      return false;
    }
    if (!values.hasOwnProperty("phone") || values.phone == "") {
      NotificationManager.error("Phone No is required");
      return false;
    }
    this.setState({ btnLoader: true });
    this.props.emaildbCheck(values.email, (response) => {
      if (response.success === 1) {
        NotificationManager.error("Emailid Already exists");
        this.setState({ btnLoader: false });
        return false;
      } else {
        var dfdata = {
          last_name: "",
          gender: values.gender,
          isvendortoo: 0,
          usertype: "consumer",
          country_id: "",
          dob: dob,
          phone: values.phone.replace(/[- )( +]/g, ""),
          first_name: values.username,
          display_name: values.username,
          businessname: "",
          new_password: values.password,
          email: values.email,
          name: values.username,
          sauser: true,
        };
        this.props.addnewCustomerdf(this.props.session, dfdata, (response) => {
          if (response.success === 0) {
            this.setState({ btnLoader: false });
            NotificationManager.error(LocaleStrings.addingfailed);
          } else if (response.success === 1) {
            this.props.pagination();
            this.props.reset();
            this.setState({ btnLoader: false });
            this.setState({ open: false });
            // alert("done");
            NotificationManager.success(LocaleStrings.addedsucessfully);
          }
        });
      }
    });
  }

  phoneChange = (value, country, e, formattedValue) => {
    this.setState({ phone: value, country: country.dialCode });
    this.props.countryPhoneLength(country.dialCode);
  };

  render() {
    // console.log("in", this.props.initialValues);
    var { handleSubmit } = this.props;
    return (
      <div className=" mt-5" style={{ overflowX: "hidden" }}>
        <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.newcustomer}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button>
          <Transition.Root show={this.state.open} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed z-10 inset-0 overflow-y-auto"
              open={this.state.open}
              onClose={this.closeModal}>
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 modal-small">
                    {" "}
                    Add New Customer
                    <div className=" sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <form
                      className=""
                      onSubmit={handleSubmit(this.onSubmitForm)}
                      encType="multipart/form-data">
                      <div>
                        <div className="border-transparent mt-5">
                          <Field
                            name="email"
                            placeholder={LocaleStrings.email}
                            label="Email"
                            type="text"
                            mandatory="true"
                            component={this.renderAllfields}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                          />
                          <div className="mt-5">
                            <Field
                              name="username"
                              mandatory="true"
                              placeholder={LocaleStrings.name}
                              label="Name"
                              type="text"
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="my-5">
                            <Field
                              name={"phone"}
                              label={"Phone Number"}
                              mandatory="true"
                              type="text"
                              component={this.renderPhonewithFlag}
                              className="cust-input-field"
                              classNameLabel="text-box-lable"
                              countryCodeEditable={false}
                              phone={this.state.phone}
                              phoneChange={this.phoneChange}
                            />
                          </div>
                          <div className="mb-5">
                            <Field
                              name="dob"
                              label={LocaleStrings.dateofbirth}
                              component={this.renderDatePickerWithLabel1}
                              mandatory={false}
                              autoComplete="off"
                              // selected={moment()}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                              classNameLabel=""
                            />
                          </div>
                          <div></div>
                          <div className="mt-5">
                            <Field
                              name="password"
                              label="Password"
                              placeholder={LocaleStrings.password}
                              type="password"
                              mandatory="true"
                              show={this.state.show}
                              setShow={this.changeShow}
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <Field
                              name="new_password"
                              label="Confirm Password"
                              placeholder={LocaleStrings.confirm_password}
                              type="password"
                              mandatory="true"
                              show={this.state.show1}
                              setShow={this.changeShow1}
                              component={this.renderAllfields}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="my-5">
                        <p>Gender</p>
                        {_.map(this.state.genderOptions, (opt, index) => {
                          //  console.log(opt)
                          return (
                            <Field
                              name="gender"
                              label={opt.name}
                              value={opt.value}
                              //checked={index == 0 ? true : false}
                              //radioOnchange={radioOnchange}
                              lebelAlign="R"
                              type="radio"
                              component={this.renderFieldRadio1}
                            />
                          );
                        })}
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={this.closeModal}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {LocaleStrings.cancel}
                          </button>
                          <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg
                              className={classNames(
                                this.state.btnLoader ? "" : "sr-only",
                                "animate-spin-medium h-5 w-5 rounded-full mx-2"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.addCustomer}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    );
  }
}

var validate = (values, ownProps) => {
  // console.log("values", values);
  var errors = {};

  var email = values["email"];
  var username = values["username"];
  var dob = values["dob"];
  var phone = values["phone"];
  var password = values["password"];
  var new_password = values["new_password"];

  if (!username || username.trim() === "") {
    errors["username"] = "Required";
  }
  if (!email || email.trim() === "") {
    errors["email"] = "Required";
  }

  if (values.email) {
    if (!validateEmail(values.email)) {
      errors.email = "Invalid email";
    }
  }

  if (!password || password.trim() === "") {
    errors["password"] = "Required";
  }
  if (!new_password || new_password.trim() === "") {
    errors["new_password"] = "Required";
  }

  let countrycode = ownProps.phoneLengthCountry;

  if (countrycode == "974" && values.phone) {
    let phone = values.phone.replace(/[- )( +]/g, "");
    if (isValidQatarPhoneNumber("+" + phone) == false) {
      errors.phone = "Please enter valid phone number";
    }
  }

  return errors;
};

function mapStateToProps(state) {
  var initVals = { gender: "" };
  var { session, user } = state;
  return {
    session,
    user,
    initialValues: initVals,
    phoneLengthCountry: state.phoneLengthCountry,
  };
}

export default connect(mapStateToProps, {
  addnewCustomerdf,
  fetchCustomer,
  emaildbCheck,
  countryPhoneLength,
})(
  reduxForm({
    validate,
    form: "CustomerAddingform",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SearchNewCustomer)
);
