import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "../../../languages/index.js";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Field, reduxForm } from "redux-form";
import { parse } from "date-fns";
import { DATE_FORMAT_WITH_TIME_WITH_SECOND } from "../../../../common/constants";
import {
  BaseComponent,
  LABEL_POSITION_TOP,
} from "../../../../common/base-component";
import moment from "moment-timezone";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { isEmpty, classNames } from "../../../../common/common-functions";
import {
  uploadNewBanner,
  addAdvertisement,
  fetchProductsByCateSubCate,
  fetchAllAdds,
  fetchProductCategory,
  fetchProductSubcategory,
  fetchallProducts,
  openAddModal,
  searchAdds,
} from "../actions/index";

import { ProductTextLocalized } from "../../../../common/common-functions";
import { NotificationManager } from "react-notifications";

const adds_on = [
  { name: "Category", value: "category", index: 0 },
  { name: "Sub-category", value: "sub-category", index: 1 },
  { name: "Product", value: "product", index: 2 },
];

class NewAddvertisement extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      setOpen: false,
      loading: false,
      showerror: false,
      adds_on: adds_on,
      btnLoader: false,
      alertMessage: "",
      fileUrl: "",
      fileArr: {},
      fileArrNew: {},
      newAddAreaType: "",
      selectedCateId: "",
      forPositioning: "",
      wheretoareaType: "",
      btnLoader: false,
      filetype: "",
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
  }
  componentDidMount() {
    this.props.fetchProductCategory(this.props.session, (callBack) => {});
    // Get ALL ProductList
    this.props.fetchallProducts(this.props.session, (callBack) => {});

    // Subcategories
    this.props.fetchProductSubcategory(this.props.session, (callBack) => {});
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.props.openAddModal(false);
    this.props.reset();
    this.setState({ fileArrNew: {} });
    this.setState({ fileUrl: "" });
  }

  onDropFiles = async (files) => {
    let file = files[0];
    let filearr = [];

    // console.log("file", file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      file.url = e.target.result;
      // file.name   = file.name;
      // file.url = reader.result;
      filearr.push(file);
      // console.log('e.target.result',e.target.result)
    };
    // console.log('filearr',filearr)
    this.setState({ filetype: file.type });
    this.setState({ fileArrNew: filearr });

    // console.log('fileData',fileData);
    // console.log('URL.createObjectURL(fileData)',URL.createObjectURL(fileData));
    this.setState({ fileUrl: URL.createObjectURL(file) });
  };

  onSubmitForm(values) {
    let type = "image";

    this.setState({ btnLoader: true });
    if (_.isEmpty(values)) {
      NotificationManager.error(LocaleStrings.ads_management_addDataText);
    }
    // console.log("allpros", allproducts);
    if (
      this.state.newAddAreaType == "category" &&
      !values.hasOwnProperty("idcategory")
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredCategory);
      // this.setState({ open: false });
      return false;
    } else if (
      this.state.newAddAreaType == "subcategory" &&
      !values.hasOwnProperty("idsubcategory")
    ) {
      NotificationManager.error(
        LocaleStrings.ads_management_requiredsubCategory
      );
      // this.setState({ open: false });
      return false;
    } else if (
      this.state.newAddAreaType == "product" &&
      !values.hasOwnProperty("idproduct")
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredProduct);
      // this.setState({ open: false });
      return false;
    } else if (
      this.state.newAddAreaType == "external" &&
      !values.hasOwnProperty("externallink")
    ) {
      NotificationManager.error("External Link is required");
      return false;
    }
    if (
      this.state.wheretoareaType == "category" &&
      !values.hasOwnProperty("whereto_categoryid")
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredProduct);
      return false;
    } else if (
      this.state.wheretoareaType == "product" &&
      !values.hasOwnProperty("whereto_productid")
    ) {
      NotificationManager.error(LocaleStrings.ads_management_requiredProduct);
      return false;
    }

    this.setState({ btnLoader: true });

    let resource_coverimage = "";
    let uploaderCounter = 0;
    let filetobeupload = [];
    let arrPromise = [];
    //let { fileArrNew } = this.state;
    let validitytill = "";
    if (typeof values.validitytill === "string") {
      validitytill = parse(
        moment(values.validitytill).format("DD-MM-YYYY HH:mm:ss"),
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      );
    } else {
      validitytill = values.validitytill;
    }
    // console.log("value", values);
    // debugger;
    // console.log("---------", this.state.fileArrNew);
    if (Object.keys(this.state.fileArrNew).length > 0) {
      // Image Exist
      let fileArrNew = this.state.fileArrNew[0];

      let ts = new Date().getTime(); // Timestamp

      let medianame = "ads" + ts + "." + "jpg"; // Image Name Creation
      if (this.state.filetype == "video/mp4") {
        type = "video";
        medianame = "ads" + ts + "." + "mp4"; // Image Name Creation
      }
      resource_coverimage = medianame;

      let ext = fileArrNew.name.split(".").pop(); // File extension

      let coverJson = {
        // type: "cover",
        type: type,
        name: medianame,
        media: fileArrNew.url,
      };
      filetobeupload.push(coverJson);
      let counter = filetobeupload.length;

      filetobeupload.map((item, index) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            this.props.uploadNewBanner(this.props.session, item, (response) => {
              if (response.success === 0) {
                resolve();
              } else {
                uploaderCounter++;
                resolve();
              }
            });
          })
        );
      });

      let newAddArrdata = {
        banner: medianame,
        add_area: values.add_area,
        categoryids:
          values.add_area == "category" ? values.idcategory.value : null,
        subcategoryids:
          values.add_area == "subcategory" ? values.idsubcategory.value : null,
        productid: values.add_area == "product" ? values.idproduct.value : null,
        externallink:
          values.add_area == "external" ? values.externallink : null,
        whereto: values.whereto,
        whereto_categoryid: values.whereto_categoryid
          ? values.whereto_categoryid.value
          : null,
        whereto_productid: values.whereto_productid
          ? values.whereto_productid.value
          : null,
        validitytill: moment(values.validitytill).format(
          DATE_FORMAT_WITH_TIME_WITH_SECOND
        ),
      };

      Promise.all(arrPromise).then(() => {
        this.props.addAdvertisement(
          this.props.session,
          newAddArrdata,
          (response) => {
            if (response.success === 0) {
              this.props.reset();
              this.setState({ btnLoader: false });
              this.setState({ open: false });
              NotificationManager.error(LocaleStrings.addingfailed);
            } else {
              this.setState({ fileArrNew: {} });
              this.setState({ fileUrl: "" });
              this.props.reset();
              this.setState({ open: false });
              this.setState({ btnLoader: false });

              NotificationManager.success(LocaleStrings.addedsucessfully);
              if (this.props.search == "") {
                this.props.fetchAllAdds(
                  this.props.session,
                  1,
                  "",
                  (CallBackResponse) => {}
                );
              } else {
                this.props.searchAdds(this.props.session, this.props.search);
              }
            }
          }
        );
      });
    } else {
      NotificationManager.error(LocaleStrings.ads_management_pleaseaddImage);
    }
  }

  _handleNewAdd(e) {
    let areaType = "";
    if (e.target.value != "" && e.target.value != null) {
      areaType = _.find(this.props.NewAddArea, {
        value: e.target.value,
      }).value;
    }
    this.setState({ newAddAreaType: areaType });
  }
  _handlewhereToshow(e) {
    let wheretoareaType = "";
    if (e.target.value != "" && e.target.value != null) {
      wheretoareaType = _.find(this.props.whereTo, {
        value: e.target.value,
      }).value;
    }
    this.setState({ wheretoareaType: wheretoareaType });
  }

  _handleposition(e) {
    let mandateType = "";
    if (e.target.value != "" && e.target.value != null) {
      mandateType = _.find(this.props.positionAdd, {
        value: e.target.value,
      }).value;
    }
    this.setState({ forPositioning: mandateType });
  }

  // Category / Subcategory  / Product Select

  _handleIdcategoryScreen(e) {
    // let selectedFiledName = e.target.name;
    // let selectedFiledValue = e.target.value;
    // if (selectedFiledName === "idcategory") {
    //   // Category
    //   this.setState({ selectedCateId: selectedFiledValue });
    //   let subCateID = this.state.selectedSubCateId;
    //   // If category || Sub-category present fetch Product
    //   this.props.fetchProductsByCateSubCate(
    //     this.props.session,
    //     selectedFiledValue,
    //     "",
    //     (callBack) => { }
    //   );
    // } else if (selectedFiledName === "idsubcategory") {
    //   // Sub-category
    //   this.setState({ selectedSubCateId: selectedFiledValue });
    //   let cateID = this.state.selectedCateId;
    //   // If category || Sub-category present fetch Product
    //   this.props.fetchProductsByCateSubCate(
    //     this.props.session,
    //     cateID,
    //     selectedFiledValue,
    //     (callBack) => { }
    //   );
    // } else if (selectedFiledName === "idproduct") {
    //   // product
    //   this.setState({ selectedProductId: selectedFiledValue });
    // } else {
    // }
  }

  // _handleIdcategoryScreen(e) {
  //   let selectedFiledName = e.target.name;
  //   let selectedFiledValue = e.target.value;

  //   if (selectedFiledName === "idcategory") {
  //     // Category
  //     this.setState({ selectedCateId: selectedFiledValue });

  //     let subCateID = this.state.selectedSubCateId;

  //     // If category || Sub-category present fetch Product
  //     this.props.fetchProductsByCateSubCate(
  //       this.props.session,
  //       selectedFiledValue,
  //       "",
  //       (callBack) => { }
  //     );
  //   } else if (selectedFiledName === "idsubcategory") {
  //     // Sub-category
  //     this.setState({ selectedSubCateId: selectedFiledValue });
  //     let cateID = this.state.selectedCateId;
  //     // If category || Sub-category present fetch Product
  //     this.props.fetchProductsByCateSubCate(
  //       this.props.session,
  //       cateID,
  //       selectedFiledValue,
  //       (callBack) => { }
  //     );
  //   } else if (selectedFiledName === "idproduct") {
  //     // product
  //     this.setState({ selectedProductId: selectedFiledValue });
  //   } else {
  //   }
  // }

  render() {
    var {
      handleSubmit,
      language,
      initialValues,
      productSubCategory,
      isaddModalopen,
      search,
    } = this.props;

    let { fileUrl, newAddAreaType, selectedCateId, forPositioning } =
      this.state;
    let coverimage = "";
    if (isEmpty(fileUrl)) {
      coverimage = "";
    } else {
      coverimage = fileUrl;
    }
    // console.log('newAddAreaType',this.state.filetype)
    var newaddAreaOptions = []; // Add Area
    var AddCateOptions = []; // Category
    var AddSubCateOptions = []; // Sub-Category
    var AddProductOptions = []; // Product
    var placeArrOptions = [];
    var whereToArrOptions = [];
    var categoryfor_banerrShowArr = [];
    var productfor_banerrShowArr = [];

    //Add Area Object Creation
    _.forEach(this.props.NewAddArea, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      newaddAreaOptions.push(obj);
    });
    _.forEach(this.props.whereTo, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      whereToArrOptions.push(obj);
    });

    _.forEach(this.props.positionAdd, function (value) {
      var obj = {
        name: ProductTextLocalized(value.name),
        value: value.value,
      };

      placeArrOptions.push(obj);
    });

    if (
      newAddAreaType === "category" ||
      newAddAreaType === "subcategory" ||
      newAddAreaType === "product"
    ) {
      //Add Category Object Creation
      _.forEach(this.props.productCategory, function (value) {
        var obj = {
          label: ProductTextLocalized(
            value.categoryname_en,
            value.categoryname_ar,
            language
          ),
          value: value.id.toString(),
        };

        AddCateOptions.push(obj);
      });
    }

    //Add Category for whereto Object Creation
    _.forEach(this.props.productCategory, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.categoryname_en,
          value.categoryname_ar,
          language
        ),
        value: value.id.toString(),
      };

      categoryfor_banerrShowArr.push(obj);
    });
    //Add PRoduct for whereto Object Creation
    _.forEach(this.props.allproductforAds, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.productname_en,
          value.productname_en,
          language
        ),
        value: value.id.toString(),
      };

      productfor_banerrShowArr.push(obj);
    });

    // if (newAddAreaType === "subcategory" || newAddAreaType === "product") {
    //   //Add Sub-Category Object Creation

    //   let filterSubcateArr = _.filter(this.props.productSubCategory, {
    //     // categoryid: parseInt(selectedCateId),
    //   });
    //   _.forEach(filterSubcateArr, function (value) {
    //     var obj = {
    //       name: ProductTextLocalized(
    //         value.subcategoryname_en,
    //         value.subcategoryname_ar,
    //         language
    //       ),
    //       value: value.id,
    //     };

    //     AddSubCateOptions.push(obj);
    //   });
    // }

    //Discount Sub-Category Object Creation

    let filterSubcateArr = _.filter(this.props.productSubCategory, {
      categoryid: parseInt(selectedCateId),
    });

    _.forEach(this.props.productSubCategory, function (value) {
      // Filter sub sub category list whose parent id
      let subSubCategory = _.filter(productSubCategory, {
        parentid: parseInt(value.id),
      });

      if (subSubCategory.length > 0) {
        // If sub sub category is present
        _.forEach(subSubCategory, function (subSubObject, subSubIndex) {
          // loop through Sub sub category

          var obj = {
            label: ProductTextLocalized(
              value.subcategoryname_en +
                " - " +
                subSubObject.subcategoryname_en,
              value.subcategoryname_ar +
                " - " +
                subSubObject.subcategoryname_ar,
              language
            ),
            value: subSubObject.id.toString(),
          };

          AddSubCateOptions.push(obj);
        });
      } else {
        // if sub sub category is not there for current subcategory

        var obj = {
          label: ProductTextLocalized(
            value.subcategoryname_en,
            value.subcategoryname_ar,
            language
          ),
          value: value.id.toString(),
        };

        AddSubCateOptions.push(obj);
      }

      // var obj = {
      //   name: ProductTextLocalized(
      //     value.subcategoryname_en,
      //     value.subcategoryname_ar,
      //     language
      //   ),
      //   value: value.id,
      // };

      // AddSubCateOptions.push(obj);
    });

    //Add Product (On select Cate | Subcate) Object Creation
    _.forEach(this.props.allproductforAds, function (value) {
      var obj = {
        label: ProductTextLocalized(
          value.productname_en,
          value.productname_ar,
          language
        ),
        value: value.id.toString(),
      };

      AddProductOptions.push(obj);
    });

    return (
      <div>
        {/* <div className="flex flex-wrap content-center justify-end ">
          <button
            onClick={this.openModal}
            type="submit"
            className="lg:w-48 md:w-44 sm:w-44 xs:w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-custWhite bg-primary hover:bg-primaryHover">
            {LocaleStrings.ads_management_newads}
            <img
              src="../images/product_management_images/add.svg"
              className="h-5 ml-2"
            />
          </button> */}
        <Transition.Root show={isaddModalopen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={isaddModalopen}
            onClose={this.closeModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide"
                  style={{ padding: 0 }}>
                  {/* MOdal Header */}
                  <div className="grid grid-cols-2 border-b-2 border-quaternary pb-4 sticky-header z-10">
                    <div className="mt-4">
                      <div className="text-md leading-6 font-medium text-secondary pl-3">
                        {LocaleStrings.ads_management_newads}
                      </div>
                    </div>
                    <div className="sm:block absolute top-0 right-0 pt-4 pr-4 ">
                      <button
                        type="button"
                        className="btn-closeModal"
                        onClick={this.closeModal}>
                        <span className="sr-only">{LocaleStrings.close}</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <form
                    className=""
                    onSubmit={handleSubmit(this.onSubmitForm.bind(this))}>
                    {/* MOdal Header Ends*/}
                    <div className="grid lg:grid-cols-2 px-6 pt-5">
                      <div>{LocaleStrings.ads_management_uploadad_banner}</div>
                      <div class="md:col-start-2 md:col-span-1 col-start-1 col-span-3">
                        <div className="dropzonecover" key={`drop`}>
                          <div className="img_wrpcover relative">
                            {this.state.filetype == "image/jpeg" &&
                            coverimage != "" ? (
                              <img src={coverimage} className="dropimgcover" />
                            ) : this.state.filetype == "video/mp4" &&
                              coverimage != "" ? (
                              <video
                                controls
                                controlsList="nodownload"
                                className="dropimgcover">
                                <source src={coverimage} type="video/mp4" />
                              </video>
                            ) : coverimage != "" ? (
                              <img src={coverimage} className="dropimgcover" />
                            ) : (
                              <div className="dropimgcover"></div>
                            )}
                          </div>

                          <Dropzone
                            onDrop={(e) => this.onDropFiles(e)}
                            accept="image/jpeg, image/png ,image.jpg ,video/mp4,image/gif"
                            multiple={false}>
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className="h-full w-full absolute top-0">
                                <input {...getInputProps()} />
                                <p className="paragraph-seconday text-xs pt-10">
                                  {coverimage == "" ? (
                                    <span> {LocaleStrings.imageupload}</span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>

                    <div className="my-5 px-6">
                      <p>{LocaleStrings.ads_management_validity}</p>
                      <p className="mb-2 text-xs text-gray-500">
                        {LocaleStrings.ads_management_dateText}
                      </p>
                      <Field
                        name="validitytill"
                        component={this.renderDateTimePickerWithLabel1}
                        mandatory={true}
                        autoComplete="off"
                        autoFocus="off"
                        // selected={moment()}
                        minDate={new Date(moment().format("YYYY,MM,DD"))}
                        className="cust-input-field"
                        classNameLabel="text-box-lable"
                      />
                    </div>
                    <div className="mb-5 px-6">
                      <Field
                        name="add_area"
                        label={LocaleStrings.link_to}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={newaddAreaOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handleNewAdd.bind(this)}
                        // value={this.props.initialValues.type}
                      />
                    </div>
                    {newAddAreaType == "category" ? (
                      <div className="mb-5 px-6">
                        <Field
                          // name="idcategory"
                          // label={LocaleStrings.discount_product_category}
                          // placeholder={LocaleStrings.select}
                          // component={this.renderSelect}
                          // mandatory="false"
                          // labelposition={LABEL_POSITION_TOP}
                          // opts={AddCateOptions}
                          // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          // classNameLabel="block text-box-lable"
                          // onChange={this._handleIdcategoryScreen.bind(this)}

                          name="idcategory"
                          label={LocaleStrings.discount_product_category}
                          placeholder={LocaleStrings.select}
                          options={AddCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleIdcategoryScreen.bind(this)}
                          isMulti={false}
                          mandatory="true"
                        />
                      </div>
                    ) : newAddAreaType == "subcategory" ? (
                      <div className="mb-5 px-6">
                        <Field
                          // name="idsubcategory"
                          // label={LocaleStrings.discount_product_subcategory}
                          // placeholder={LocaleStrings.select}
                          // component={this.renderSelect}
                          // mandatory="false"
                          // labelposition={LABEL_POSITION_TOP}
                          // opts={AddSubCateOptions}
                          // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          // classNameLabel="block text-box-lable"
                          // onChange={this._handleIdcategoryScreen.bind(this)}

                          name="idsubcategory"
                          label={LocaleStrings.discount_product_subcategory}
                          placeholder={LocaleStrings.select}
                          options={AddSubCateOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleIdcategoryScreen.bind(this)}
                          isMulti={false}
                          mandatory="true"
                        />
                      </div>
                    ) : newAddAreaType == "product" ? (
                      <div className="mb-5 px-6">
                        <Field
                          // name="idproduct"
                          // label={LocaleStrings.discount_product}
                          // placeholder={LocaleStrings.select}
                          // component={this.renderAutoComplete}
                          // mandatory="false"
                          // labelposition={LABEL_POSITION_TOP}
                          // opts={AddProductOptions}
                          // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          // classNameLabel="block text-box-lable"
                          // onChange={this._handleIdcategoryScreen.bind(this)}

                          name="idproduct"
                          label={LocaleStrings.discount_product}
                          placeholder={LocaleStrings.select}
                          options={AddProductOptions}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleIdcategoryScreen.bind(this)}
                          isMulti={false}
                          mandatory="true"
                        />
                      </div>
                    ) : newAddAreaType == "external" ? (
                      <div className="mb-5 px-6">
                        <Field
                          name="externallink"
                          placeholder={
                            LocaleStrings.ads_management_external_link
                          }
                          label={LocaleStrings.ads_management_external_link}
                          type="text"
                          component={this.renderAllfields}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mb-5 px-6">
                      <Field
                        name="whereto"
                        label={LocaleStrings.ads_management_showbanner_to}
                        placeholder={LocaleStrings.select}
                        component={this.renderSelect}
                        mandatory="true"
                        labelposition={LABEL_POSITION_TOP}
                        opts={whereToArrOptions}
                        className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                        classNameLabel="block text-box-lable"
                        onChange={this._handlewhereToshow.bind(this)}
                      />
                    </div>

                    {this.state.wheretoareaType == "category" ? (
                      <div className="mb-5 px-6">
                        <Field
                          // name="whereto_categoryid"
                          // label="Showing Categoryid"
                          // placeholder={LocaleStrings.select}
                          // component={this.renderSelect}
                          // mandatory="false"
                          // labelposition={LABEL_POSITION_TOP}
                          // opts={categoryfor_banerrShowArr}
                          // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          // classNameLabel="block text-box-lable"
                          name="whereto_categoryid"
                          label="Showing Categoryid"
                          placeholder={LocaleStrings.select}
                          options={categoryfor_banerrShowArr}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          isMulti={false}
                          mandatory="true"
                        />
                      </div>
                    ) : this.state.wheretoareaType == "product" ? (
                      <div className="mb-5 px-6">
                        <Field
                          // name="whereto_productid"
                          // label="Showing Productid"
                          // placeholder={LocaleStrings.select}
                          // component={this.renderSelect}
                          // mandatory="false"
                          // labelposition={LABEL_POSITION_TOP}
                          // opts={productfor_banerrShowArr}
                          // className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          // classNameLabel="block text-box-lable"

                          name="whereto_productid"
                          label="Showing Productid"
                          placeholder={LocaleStrings.select}
                          options={productfor_banerrShowArr}
                          component={this.renderAutoComplete}
                          labelposition={LABEL_POSITION_TOP}
                          labelKey="label"
                          valueKey="value"
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          isMulti={false}
                          mandatory="true"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div className="mb-5 px-6">
                        <Field
                          name="position"
                          label="Position"
                          mandatory="true"
                          placeholder={LocaleStrings.select}
                          component={this.renderSelect}
                          labelposition={LABEL_POSITION_TOP}
                          opts={placeArrOptions}
                          className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-base border-ternary focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                          classNameLabel="block text-box-lable"
                          onChange={this._handleposition.bind(this)}
                          // value={this.props.initialValues.type}
                        />
                      </div> */}
                    {/* {forPositioning == "random" ? (
                        <div className="lg:flex mb-5 pl-6">
                          <div>
                            <p className="text-xs pt-3 pr-2">
                              {LocaleStrings.ads_management_repeat}
                            </p>
                          </div>
                          <div>
                            {" "}
                            <Field
                              name="repeattimes"
                              type="text"
                              placeholder="No. of times"
                              component={this.renderFieldAds}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                          <div>
                            <p className="text-xs pt-3 px-3">
                              {LocaleStrings.ads_management_afterevery}
                            </p>
                          </div>
                          <div>
                            {" "}
                            <Field
                              name="rowsno"
                              placeholder="No. of rows"
                              type="text"
                              component={this.renderFieldAds}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}

                    <div className="my-10">
                      <div className="flex justify-center">
                        <button type="submit" className="button flex">
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "sr-only",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          <span> {LocaleStrings.ads_management_save}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      // </div>
    );
  }
}

function validate(values) {
  var errors = {};
  var errors = {};
  var requiredFields = ["validitytill", "add_area", "position"];

  requiredFields.forEach((field) => {
    // if (typeof values[field] != 'undefined'){
    if (!values[field] || values[field] === "") {
      errors[field] = "Required";
    }
    // }
  });
  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  // console.log('productByCateSubCate',state.addProductByCateSubCate)
  return {
    session,
    user,
    language: state.language,
    NewAddArea: state.NewAddArea,
    positionAdd: state.positionAdd,
    whereTo: state.whereTo,
    productCategory: state.addProductCategory
      ? state.addProductCategory.data != "undefined"
        ? state.addProductCategory.data
        : []
      : [],
    productSubCategory: state.addProductSubCategory
      ? state.addProductSubCategory.data != "undefined"
        ? state.addProductSubCategory.data
        : []
      : [],
    productByCateSubCate: state.addProductByCateSubCate
      ? state.addProductByCateSubCate.data !== "undefined"
        ? state.addProductByCateSubCate.data
        : []
      : [],
    allproductforAds: state.allproductforAds
      ? state.allproductforAds.data !== "undefined"
        ? state.allproductforAds.data
        : []
      : [],
    isaddModalopen: state.isaddModalopen,
  };
}

export default reduxForm({
  validate,
  form: "ADDADVERTISEMENT",
})(
  connect(mapStateToProps, {
    uploadNewBanner,
    addAdvertisement,
    fetchProductsByCateSubCate,
    fetchAllAdds,
    fetchProductCategory,
    fetchProductSubcategory,
    fetchallProducts,
    openAddModal,
    searchAdds,
  })(NewAddvertisement)
);
